import { ArrowRight, ArrowUpRight } from "lucide-react";
import { useRef, useState, useEffect } from "react";
import placeholderimage from "../img/image.png";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
function Blogs() {
  const campaignRef = useRef(null);

  useEffect(() => {
    const campaign = campaignRef.current;
    if (campaign) {
      const scroll = () => {
        campaign.scrollLeft += 1;
        if (campaign.scrollLeft >= campaign.scrollWidth / 2) {
          campaign.scrollLeft = 0;
        }
      };
      const intervalId = setInterval(scroll, 50);
      return () => clearInterval(intervalId);
    }
  }, []);

  //fetch
  const [posts, setPosts] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://bezaapi.plutotechnologies.org/api/posts${cat}`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    const fetchPodcast = async () => {
      try {
        const res = await axios.get(
          `https://bezaapi.plutotechnologies.org/api/podcasts${cat}`
        );
        setPodcasts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPodcast();
    fetchData();
  }, [cat]);

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };
  return (
    <div className="bg-white min-h-screen">
      <section className="py-24 max-w-7xl mx-auto px-4 md:px-6">
        <div className="inline-block rounded-full border border-black px-4 py-1 mb-6">
          Our Projects
        </div>
        <h2 className="text-4xl md:text-5xl font-bold mb-12 max-w-4xl">
          We hold various events, campaigns and raise funds for the
          implementation of projects to preserve the{" "}
          <span className="bg-[#E5EDDE] px-2 rounded-lg">environment</span>
        </h2>

        <div className="overflow-x-auto pb-6">
          <div className="flex space-x-6 min-w-max">
            {posts.map((post) => (
              <div
                key={post.id}
                className="bg-[#E5EDDE] rounded-3xl p-6 w-80 flex flex-col"
              >
                <h3 className="text-2xl font-bold mb-2">{post.title}</h3>

                <div className="flex justify-between items-end">
                  <Link className="link" to={`/post/${post.id}`}>
                    <button className="text-black font-semibold flex items-center">
                      Read More <ArrowRight className="ml-2 w-5 h-5" />
                    </button>
                  </Link>
                  <img
                    src={`../upload/${post.img}`}
                    alt="People planting a tree"
                    layout="fill"
                    className="w-[200px] h-[200px] object-cover rounded-full"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-12 overflow-hidden">
        <div ref={campaignRef} className="whitespace-nowrap">
          {[...Array(2)].map((_, i) => (
            <div key={i} className="inline-flex items-center space-x-8">
              <div className="text-8xl font-bold tracking-tighter text-gray-200 uppercase">
                Green
              </div>
              <img
                src={placeholderimage}
                alt="People planting a tree"
                layout="fill"
                className="w-[200px] h-[200px] rounded-full"
              />

              <div className="text-8xl font-bold tracking-tighter">
                Campaign
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="py-24 max-w-7xl mx-auto px-4 md:px-6">
        <div className="inline-block rounded-full border border-black px-4 py-1 mb-6">
          Our Podcasts
        </div>
        <h2 className="text-4xl md:text-5xl font-bold mb-12 max-w-4xl">
          Listen to our environmental{" "}
          <span className="bg-[#E5EDDE] px-2 rounded-lg">podcasts</span>
        </h2>

        <div className="overflow-x-auto pb-6">
          <div className="flex space-x-6 min-w-max">
            {podcasts.map((podcast, index) => (
              <div
                key={index}
                className={`bg-[#E5EDDE] rounded-3xl p-6 w-80 flex flex-col`}
              >
                <h3 className="text-2xl font-bold mb-2">{podcast.title}</h3>
                <p className="text-gray-600 mb-4 flex-grow"></p>
                <div className="flex justify-between items-end">
                  <button className="text-black font-semibold flex items-center">
                    Listen Now <ArrowUpRight className="ml-2 w-5 h-5" />
                  </button>
                  <img
                    src={`../upload/${podcast.img}`}
                    alt="People planting a tree"
                    layout="fill"
                    className="w-[200px] h-[200px] rounded-full"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blogs;
