// import { useState, useEffect } from "react";
// import { Search, Filter } from "lucide-react";
// import { Link, useLocation } from "react-router-dom";
// import axios from "axios";

// import { ArrowRight, ArrowUpRight } from "lucide-react";

// function Podcasts() {
//   //fetch
//   const [posts, setPosts] = useState([]);

//   const cat = useLocation().search;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const res = await axios.get(`/podcasts${cat}`);
//         setPosts(res.data);
//       } catch (err) {
//         console.log(err);
//       }
//     };
//     fetchData();
//   }, [cat]);

//   const getText = (html) => {
//     const doc = new DOMParser().parseFromString(html, "text/html");
//     return doc.body.textContent;
//   };
//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectedCategory, setSelectedCategory] = useState("");

//   const filteredBlogs = blogPosts.filter(
//     (blog) =>
//       blog.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
//       (selectedCategory === "" || blog.category === selectedCategory)
//   );

//   return (
//     <div className="bg-white min-h-screen py-24">
//       <div className="max-w-7xl mx-auto px-4 md:px-6">
//         <h1 className="text-5xl font-bold mb-8">Our Blog</h1>

//         <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-12 space-y-4 md:space-y-0">
//           <div className="relative w-full md:w-96">
//             <input
//               type="text"
//               placeholder="Search blogs..."
//               className="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#2D6A4F]"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             <Search className="absolute right-3 top-2.5 text-gray-400" />
//           </div>

//           <div className="relative w-full md:w-auto">
//             <select
//               className="w-full md:w-auto px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#2D6A4F] appearance-none"
//               value={selectedCategory}
//               onChange={(e) => setSelectedCategory(e.target.value)}
//             >
//               <option value="">All Categories</option>
//               {categories.map((category) => (
//                 <option key={category} value={category}>
//                   {category}
//                 </option>
//               ))}
//             </select>
//             <Filter className="absolute right-3 top-2.5 text-gray-400 pointer-events-none" />
//           </div>
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//           {posts.map((post) => (
//             <Link className="group" key={post.id} to={`/post/${post.id}`}>
//               <div className="bg-white rounded-3xl overflow-hidden shadow-lg transition-transform transform group-hover:scale-105">
//                 <img
//                   src={`../upload/${post.img}`}
//                   className="w-full h-48 object-cover"
//                 />
//                 <div className="p-6">
//                   <span className="inline-block px-3 py-1 bg-[#E5EDDE] text-[#2D6A4F] rounded-full text-sm font-semibold mb-2">
//                     {post.cat}
//                   </span>
//                   <h2 className="text-xl font-bold mb-2 group-hover:text-[#2D6A4F]">
//                     {post.title}
//                   </h2>
//                   <button className="text-black font-semibold flex items-center">
//                     Read More <ArrowRight className="ml-2 w-5 h-5" />
//                   </button>
//                 </div>
//               </div>
//             </Link>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Podcasts;

import { useState, useEffect } from "react";
import { Search, Filter, Play } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

const categories = [
  "Climate Talks",
  "Wildlife Stories",
  "Sustainable Living",
  "Environmental Policy",
  "Green Tech",
];

const podcasts = [
  {
    id: 1,
    title: "The Future of Renewable Energy",
    description:
      "Exploring the latest advancements in renewable energy technologies.",
    category: "Green Tech",
    image: "/placeholder.svg?height=200&width=200",
  },
  {
    id: 2,
    title: "Wildlife Conservation Success Stories",
    description:
      "Inspiring tales of successful wildlife conservation efforts around the world.",
    category: "Wildlife Stories",
    image: "/placeholder.svg?height=200&width=200",
  },
  {
    id: 3,
    title: "Sustainable Urban Planning",
    description:
      "How cities are adapting to create more sustainable and livable environments.",
    category: "Sustainable Living",
    image: "/placeholder.svg?height=200&width=200",
  },
  // Add more podcasts as needed
];

export default function PodcastsPage() {
  //old
  const [posts, setPosts] = useState([]);

  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://bezaapi.plutotechnologies.org/api/podcasts${cat}`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  // const filteredBlogs = blogPosts.filter(
  //   (blog) =>
  //     blog.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //     (selectedCategory === "" || blog.category === selectedCategory)
  // );
  //new
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const filteredPodcasts = posts.filter(
    (podcast) =>
      podcast.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory === "" || podcast.category === selectedCategory)
  );

  return (
    <div className="bg-white min-h-screen py-24">
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        <h1 className="text-5xl font-bold mb-8">Our Podcasts</h1>

        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-12 space-y-4 md:space-y-0">
          <div className="relative w-full md:w-96">
            <input
              type="text"
              placeholder="Search podcasts..."
              className="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#2D6A4F]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute right-3 top-2.5 text-gray-400" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPodcasts.map((podcast) => (
            <Link
              to={`/podcast/${podcast.id}`}
              key={podcast.id}
              className="group"
            >
              <div className="bg-white rounded-3xl overflow-hidden shadow-lg transition-transform transform group-hover:scale-105">
                <div className="relative">
                  <img
                    src={`../upload/${podcast.img}`}
                    alt={podcast.title}
                    width={200}
                    height={200}
                    className="w-full h-48 object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                    <Play className="w-16 h-16 text-white" />
                  </div>
                </div>
                <div className="p-6">
                  <span className="inline-block px-3 py-1 bg-[#E5EDDE] text-[#2D6A4F] rounded-full text-sm font-semibold mb-2">
                    {podcast.cat}
                  </span>
                  <h2 className="text-xl font-bold mb-2 group-hover:text-[#2D6A4F]">
                    {podcast.title}
                  </h2>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
