import React from "react";
import {
  ArrowUpRight,
  LeafyGreen,
  Recycle,
  TreePine,
  ArrowRight,
} from "lucide-react";
import placeholderimage from "../img/image.png";
function Hero() {
  return (
    <>
      <section className="mt-12 md:mt-24 relative">
        <div className="absolute top-0 left-0 w-16 h-16 bg-[#E5EDDE] rounded-full flex items-center justify-center">
          <Recycle className="w-8 h-8 text-[#2D6A4F]" />
        </div>
        <div className="absolute top-24 right-24 w-16 h-16 bg-[#E5EDDE] rounded-full flex items-center justify-center">
          <TreePine className="w-8 h-8 text-[#2D6A4F]" />
        </div>
        <h1 className="text-5xl md:text-6xl font-bold text-center leading-tight">
          Building{" "}
          <span className="bg-[#E5EDDE] px-4 rounded-full"> Eco-Aware</span> for
          <br />
          Youth Through Climate Education
        </h1>
        <p className="mt-6 text-center text-gray-600 max-w-2xl mx-auto">
          We are an organization that educates society about the problems of
          ecology and nature
        </p>
        <div className="mt-12 flex justify-center">
          <button className="px-6 py-3 bg-[#E5EDDE] text-black rounded-full text-lg font-semibold flex items-center">
            Learn More <ArrowRight className="ml-2 w-5 h-5" />
          </button>
        </div>
      </section>
      <div className="w-full py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 items-center lg:h-[50vh]">
          {/* Logo Column */}
          <div className="bg-[#D1E2C4] rounded-3xl p-6 flex items-center justify-center">
            <div className="w-12 h-12 bg-white rounded-lg flex items-center justify-center">
              <LeafyGreen className="w-6 h-6 text-[#7baa22]" />
            </div>
          </div>

          {/* Trees Planted and Educational Programs Column */}
          <div className="flex flex-col gap-4 h-[75%]">
            <div className="bg-gray-100 rounded-3xl p-6 h-[50%]">
              <h3 className="text-2xl font-bold mb-2">+685 trees planted</h3>
            </div>
            <div className="bg-[#E5EDDE] rounded-3xl p-6 h-[50%]">
              <h3 className="text-2xl font-bold mb-2">
                6 educational programs
              </h3>
            </div>
          </div>

          {/* Share Results Column */}
          <div className="relative rounded-3xl overflow-hidden h-full">
            <img
              src={placeholderimage}
              alt="People planting a tree"
              layout="fill"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
              <div className="text-center">
                <div className="bg-white rounded-full p-2 inline-block mb-4">
                  <LeafyGreen className="w-6 h-6 text-[#7baa22]" />
                </div>
                <h3 className="text-white text-2xl font-bold mb-2">
                  Share with us your results
                </h3>
              </div>
            </div>
          </div>

          {/* Donate Column */}
          <div className="relative rounded-3xl overflow-hidden bg-[#8CC084] h-[75%]">
            <div className="absolute inset-0">
              <img
                src={placeholderimage}
                alt="People planting a tree"
                layout="fill"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="relative p-6 flex flex-col h-full">
              <div className="flex gap-2 mb-4">
                <span className="bg-white text-[#8CC084] px-3 py-1 rounded-full text-sm font-semibold">
                  Support
                </span>
                <span className="bg-white text-[#8CC084] px-3 py-1 rounded-full text-sm font-semibold">
                  Charity
                </span>
              </div>
              <div className="flex-grow"></div>
              <h3 className="text-white text-2xl font-bold mb-2">
                Donate and help the environment
              </h3>
              <ArrowUpRight className="text-white w-6 h-6" />
            </div>
          </div>

          {/* International Environment Day Column */}
          <div className="bg-[#E5EDDE] rounded-3xl p-6 flex flex-col items-center justify-center text-center h-50">
            <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center mb-4">
              <LeafyGreen className="w-6 h-6 text-[#7baa22]" />
            </div>
            <h3 className="text-xl font-bold">International environment day</h3>
          </div>
        </div>
      </div>
      <footer className="mt-24 bg-[#E5EDDE] py-4">
        <div className="max-w-6xl mx-auto px-4 md:px-6 flex items-center overflow-x-auto whitespace-nowrap">
          {Array(6)
            .fill(null)
            .map((_, i) => (
              <div key={i} className="flex items-center mr-6">
                <TreePine className="w-5 h-5 mr-2 text-[#2D6A4F]" />
                <span className="text-sm font-medium">Green The Planet</span>
              </div>
            ))}
        </div>
      </footer>
      <section className="py-24 max-w-6xl mx-auto px-4 md:px-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-8 md:mb-0 md:w-1/2">
            <h2 className="text-4xl font-bold mb-4">
              We strive to protect nature for future generations.
            </h2>
            <p className="text-gray-600 mb-6">
              The GreenWorld has carried out many successful projects for
              reforestation and biodiversity conservation.
            </p>
            <div className="flex space-x-4">
              <button className="px-6 py-3 bg-[#E5EDDE] text-black rounded-full text-lg font-semibold">
                About Us
              </button>
              <button className="px-6 py-3 border border-black rounded-full text-lg font-semibold flex items-center">
                View Campaigns <ArrowRight className="ml-2 w-5 h-5" />
              </button>
            </div>
          </div>
          <div className="md:w-1/3">
            <div className="w-full h-64  bg-gray-200 rounded-3xl">
              <img
                src={placeholderimage}
                alt="People planting a tree"
                layout="fill"
                className="w-full h-full rounded-3xl object-cover"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
