import { useState } from "react";
import { ArrowRight, ChevronDown } from "lucide-react";
import placeholderimage from "../img/image.png";
const FAQItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`rounded-2xl overflow-hidden transition-all duration-300 ${
        isOpen ? "bg-[#E5EDDE]" : "bg-gray-100"
      }`}
    >
      <button
        className="w-full px-6 py-4 text-left flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-xl font-semibold">{item.question}</span>
        <ChevronDown
          className={`w-6 h-6 transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && (
        <div className="px-6 pb-4">
          <p>{item.answer}</p>
        </div>
      )}
    </div>
  );
};

export default function Faq() {
  const faqItems = [
    {
      question: "You can become a volunteer",
      answer:
        "Join our team of dedicated volunteers and make a real difference in environmental conservation. We offer various opportunities to get involved in our projects and campaigns.",
    },
    {
      question: "You can share information",
      answer:
        "Help us spread the word about the importance of protecting nature and the environment by sharing our news and posts on social media. Your voice can make a significant impact!",
    },
    {
      question: "You can make a donation",
      answer:
        "Your financial support helps us continue our important work. Every donation, no matter how small, contributes to our efforts in preserving the environment for future generations.",
    },
    {
      question: "You can participate in campaigns",
      answer:
        "Take part in our various campaigns aimed at raising awareness and making a tangible difference. From local clean-ups to global initiatives, there's a campaign for everyone.",
    },
  ];

  return (
    <div className="bg-white min-h-screen py-24">
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        <div className="flex justify-between items-center mb-12">
          <h2 className="text-4xl md:text-5xl font-bold">Get Involved</h2>
          <ArrowRight className="w-8 h-8" />
        </div>

        <p className="text-gray-600 mb-8">
          Information on the various ways in which visitors can contribute to
          the work of The GreenWorld
        </p>

        <div className="space-y-4 mb-16">
          {faqItems.map((item, index) => (
            <FAQItem key={index} item={item} />
          ))}
        </div>

        <div className="text-center mb-16">
          <button className="px-6 py-2 border border-black rounded-full text-lg font-semibold">
            Our Gallery
          </button>
        </div>

        <div className="relative">
          <div className="absolute inset-0 bg-[#E5EDDE] rounded-[100px] -rotate-3"></div>
          <div className="relative z-10 text-center py-16">
            <h3 className="text-4xl font-bold mb-4">
              Evaluate your results
              <br />
              by browsing the gallery
            </h3>
            <div className="relative inline-block">
              <img
                src={placeholderimage}
                alt="People planting a tree"
                layout="fill"
                className="w-[600px] h-[400px] rounded-[50px] overflow-hidden"
              />
              <button className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white px-4 py-2 rounded-full font-semibold">
                SEE ALL PHOTOS
              </button>
            </div>
          </div>
          <div className="absolute top-4 left-4 w-8 h-8 bg-white rounded-full"></div>
          <div className="absolute bottom-4 right-4 w-8 h-8 bg-white rounded-full"></div>
          <div className="absolute top-1/4 right-8 w-6 h-6 bg-white rounded-full"></div>
          <div className="absolute bottom-1/4 left-8 w-6 h-6 bg-white rounded-full"></div>
        </div>
      </div>
    </div>
  );
}
