import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Logo from "../img/logo.png";
import {
  Menu,
  X,
  Folder,
  Home,
  Newspaper,
  LeafyGreen,
  TrendingUp,
  AudioLines,
} from "lucide-react";

import placeholderimage from "../img/logo.png";
const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white shadow-sm w-full sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0 flex-row flex items-center">
            <Link to="/" className="flex-row flex">
              {/* <img
                src={placeholderimage}
                alt="GreenWorld Logo"
                width={40}
                height={40}
              /> */}
              <LeafyGreen className="w-6 h-6 text-[#7baa22]" />
              <span className="ml-2 text-xl font-bold">EcoAware Youth</span>
            </Link>
          </div>

          <nav className="hidden md:flex space-x-8">
            <NavItem
              to="/"
              icon={<Home className="w-5 h-5 text-[#7baa22]" />}
              text="Home"
            />
            <NavItem
              to="/blogs"
              icon={<Newspaper className="w-5 h-5 text-[#7baa22]" />}
              text="Blogs"
            />
            <NavItem
              to="/podcasts"
              icon={<AudioLines className="w-5 h-5 text-[#7baa22]" />}
              text="Podcasts"
            />
            <NavItem
              to="/about"
              icon={<LeafyGreen className="w-5 h-5 text-[#7baa22]" />}
              text="About"
            />
          </nav>

          <div className="hidden md:flex items-center">
            <Link
              to="/contact"
              className="bg-black text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-gray-800 transition-colors"
            >
              Contact Us
            </Link>
            <span>{currentUser?.username}</span>

            {currentUser ? (
              <span
                className="bg-black text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-gray-800 transition-colors"
                onClick={logout}
              >
                Logout
              </span>
            ) : (
              <Link
                to="/login"
                className="bg-black text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-gray-800 transition-colors"
              >
                Login
              </Link>
            )}
            <span className="write">
              <Link
                to="/write"
                className="bg-black text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-gray-800 transition-colors"
              >
                Write
              </Link>
              <Link
                to="/sing"
                className="bg-black text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-gray-800 transition-colors"
              >
                Sing
              </Link>
            </span>
          </div>

          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-500 hover:text-gray-600"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <MobileNavItem
              to="/"
              icon={<Folder className="w-5 h-5" />}
              text="Home"
            />
            <MobileNavItem
              to="/news"
              icon={<Newspaper className="w-5 h-5" />}
              text="News"
            />
            <MobileNavItem
              to="/campaigns"
              icon={<TrendingUp className="w-5 h-5" />}
              text="Campaigns"
            />
            <Link
              to="/contact"
              className="block bg-black text-white px-3 py-2 rounded-md text-base font-medium hover:bg-gray-800 transition-colors"
            >
              Contact Us
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

function NavItem({ to, icon, text }) {
  return (
    <Link
      to={to}
      className="flex items-center bg-[#EFEFEF] rounded-full border-2 pr-4 border-[#EFEFEF] text-gray-700 hover:text-gray-900"
    >
      <span className="bg-[#fff] p-2 rounded-full">{icon}</span>
      <span className="ml-2">{text}</span>
    </Link>
  );
}

function MobileNavItem({ to, icon, text }) {
  return (
    <Link
      to={to}
      className="flex items-center text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-base font-medium"
    >
      <span className="bg-gray-100 p-2 rounded-full mr-3">{icon}</span>
      {text}
    </Link>
  );
  // return (
  //   <div className="navbar">
  //     <div className="container">
  //       <div className="logo">
  //         <Link to="/">
  //           <img src={Logo} alt="" />
  //         </Link>
  //       </div>
  //       <div className="links">
  //         <Link className="link" to="/?cat=art">
  //           <h6>ART</h6>
  //         </Link>
  //         <Link className="link" to="/?cat=science">
  //           <h6>SCIENCE</h6>
  //         </Link>
  //         <Link className="link" to="/?cat=technology">
  //           <h6>TECHNOLOGY</h6>
  //         </Link>
  //         <Link className="link" to="/?cat=cinema">
  //           <h6>CINEMA</h6>
  //         </Link>
  //         <Link className="link" to="/?cat=design">
  //           <h6>DESIGN</h6>
  //         </Link>
  //         <Link className="link" to="/?cat=food">
  //           <h6>FOOD</h6>
  //         </Link>
  //         <span>{currentUser?.username}</span>
  //         {currentUser ? (
  //           <span onClick={logout}>Logout</span>
  //         ) : (
  //           <Link className="link" to="/login">
  //             Login
  //           </Link>
  //         )}
  //         <span className="write">
  //           <Link className="link" to="/write">
  //             Write
  //           </Link>
  //         </span>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Navbar;
