import React, { useState, useRef, useEffect } from "react";
import { Play, ArrowLeft, Pause, SkipBack, SkipForward } from "lucide-react";
import Edit from "../img/edit.png";
import Delete from "../img/delete.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import axios from "axios";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import DOMPurify from "dompurify";
// import { Link } from "react-router-dom";\
const Singlepodcast = () => {
  const [post, setPost] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  const postId = location.pathname.split("/")[2];

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://bezaapi.plutotechnologies.org/api/podcasts/${postId}`
        );
        setPost(res.data);
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://bezaapi.plutotechnologies.org/api/podcasts/${postId}`
      );
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };
  //audio
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const audioRef = useRef(null);
  const progressBarRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const setAudioData = () => {
      setDuration(audio.duration);
      setCurrentTime(audio.currentTime);
    };

    const setAudioTime = () => setCurrentTime(audio.currentTime);

    // Add event listeners
    audio.addEventListener("loadeddata", setAudioData);
    audio.addEventListener("timeupdate", setAudioTime);

    // Remove event listeners on cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    };
  }, []);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleRewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10;
    }
  };

  const handleForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10;
    }
  };

  const handleProgressChange = (event) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = parseFloat(event.target.value);
      setCurrentTime(audio.currentTime);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };
  return (
    <>
      <div className="bg-white min-h-screen py-24">
        <div className="max-w-4xl mx-auto px-4 md:px-6">
          <Link
            href="/podcasts"
            className="inline-flex items-center text-[#2D6A4F] hover:underline mb-8"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Podcasts
          </Link>

          <div className="flex flex-col md:flex-col items-start md:items-center mb-8">
            <img
              src={`../upload/${post?.img}`}
              alt={post.title}
              width={800}
              height={400}
              className="w-full h-64 md:h-96 object-cover rounded-3xl mb-8"
            />
            <div className="w-full">
              <span className="inline-block px-3 py-1 bg-[#E5EDDE] text-[#2D6A4F] rounded-full text-sm font-semibold mb-4">
                {post.cat}
              </span>
              <h1 className="text-4xl md:text-5xl font-bold mb-4">
                {post.title}
              </h1>
              <div className="flex items-center text-gray-600 mb-8">
                <span>{post.username}</span>
                <span className="mx-2">•</span>
                <span>Posted {moment(post.date).fromNow()}</span>
                {currentUser && currentUser.username === post.username && (
                  <div className="edit">
                    <Link to={`/sing?edit=2`} state={post}>
                      <img src={Edit} alt="" />
                    </Link>
                    <img onClick={handleDelete} src={Delete} alt="" />
                  </div>
                )}
              </div>
              <div className="w-full max-w-md mx-auto">
                <audio ref={audioRef} src={`../upload/${post.audio}`} />
                <div className="bg-[#E5EDDE] rounded-full p-2 flex justify-between items-center mb-4">
                  <button
                    className="p-2 hover:text-[#2D6A4F] focus:outline-none focus:ring-2 focus:ring-[#2D6A4F] rounded-full"
                    onClick={handleRewind}
                    aria-label="Rewind 10 seconds"
                  >
                    <SkipBack className="w-6 h-6" />
                  </button>
                  <button
                    className={`p-4 rounded-full text-white hover:bg-[#1A4031] focus:outline-none focus:ring-2 focus:ring-[#2D6A4F] ${
                      isPlaying ? "bg-[#1A4031]" : "bg-[#2D6A4F]"
                    }`}
                    onClick={handlePlayPause}
                    aria-label={isPlaying ? "Pause" : "Play"}
                  >
                    {isPlaying ? (
                      <Pause className="w-8 h-8" />
                    ) : (
                      <Play className="w-8 h-8" />
                    )}
                  </button>
                  <button
                    className="p-2 hover:text-[#2D6A4F] focus:outline-none focus:ring-2 focus:ring-[#2D6A4F] rounded-full"
                    onClick={handleForward}
                    aria-label="Forward 10 seconds"
                  >
                    <SkipForward className="w-6 h-6" />
                  </button>
                </div>
                <div className="flex items-center space-x-2 text-sm">
                  <span>{formatTime(currentTime)}</span>
                  <input
                    type="range"
                    ref={progressBarRef}
                    className="w-full"
                    min={0}
                    max={duration}
                    value={currentTime}
                    onChange={handleProgressChange}
                  />
                  <span>{formatTime(duration)}</span>
                </div>
              </div>
              <div
                className="prose prose-lg max-w-none mb-16"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.description),
                }}
              />
            </div>
          </div>

          <div
            className="prose prose-lg max-w-none mb-16"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
          <Menu cat={post.cat} />
        </div>
      </div>
      {/* <div className="bg-white min-h-screen py-10">
        <div className="max-w-4xl mx-auto px-4 md:px-6">
          <Link
            href="/blogs"
            className="inline-flex items-center text-[#2D6A4F] hover:underline mb-8"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Blogs
          </Link>

          <img
            src={`../upload/${post?.img}`}
            alt={post.title}
            width={800}
            height={400}
            className="w-full h-64 md:h-96 object-cover rounded-3xl mb-8"
          />

          <span className="inline-block px-3 py-1 bg-[#E5EDDE] text-[#2D6A4F] rounded-full text-sm font-semibold mb-4">
            {post.cat}
          </span>

          <h1 className="text-4xl md:text-5xl font-bold mb-4">{post.title}</h1>

          <div className="flex items-center text-gray-600 mb-8">
            <span>{post.username}</span>
            <span className="mx-2">•</span>
            <span>Posted {moment(post.date).fromNow()}</span>
            {currentUser && currentUser.username === post.username && (
              <div className="edit">
                <Link to={`/write?edit=2`} state={post}>
                  <img src={Edit} alt="" />
                </Link>
                <img onClick={handleDelete} src={Delete} alt="" />
              </div>
            )}
          </div>

          <div
            className="prose prose-lg max-w-none mb-16"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(post.description),
            }}
          />
          <div className="single">
            {/* <div className="content">
              <img src={`../upload/${post?.img}`} alt="" />
              <div className="user">
                {post.userImg && <img src={post.userImg} alt="" />}
                <div className="info">
                  <span>{post.username}</span>
                  <p>Posted {moment(post.date).fromNow()}</p>
                </div>
                {currentUser.username === post.username && (
                  <div className="edit">
                    <Link to={`/write?edit=2`} state={post}>
                      <img src={Edit} alt="" />
                    </Link>
                    <img onClick={handleDelete} src={Delete} alt="" />
                  </div>
                )}
              </div>
              <h1>{post.title}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.description),
                }}
              ></p>{" "}
            </div> 
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Singlepodcast;
