import { MapPin, Phone, Mail } from "lucide-react";

export default function ContactUs() {
  return (
    <div className="bg-white min-h-screen py-24">
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        <h1 className="text-5xl font-bold mb-8">Contact Us</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
          <div>
            <p className="text-xl mb-8">
              We'd love to hear from you! Whether you have a question about our
              initiatives, want to volunteer, or are interested in partnering
              with us, please don't hesitate to reach out.
            </p>

            <div className="space-y-6">
              <div className="flex items-start">
                <MapPin className="w-6 h-6 text-[#2D6A4F] mr-4 mt-1" />
                <div>
                  <h3 className="font-semibold mb-1">Address</h3>
                  <p>123 Green Street, Eco City, 12345, Country</p>
                </div>
              </div>

              <div className="flex items-start">
                <Phone className="w-6 h-6 text-[#2D6A4F] mr-4 mt-1" />
                <div>
                  <h3 className="font-semibold mb-1">Phone</h3>
                  <p>+1 (555) 123-4567</p>
                </div>
              </div>

              <div className="flex items-start">
                <Mail className="w-6 h-6 text-[#2D6A4F] mr-4 mt-1" />
                <div>
                  <h3 className="font-semibold mb-1">Email</h3>
                  <p>info@greenworld.org</p>
                </div>
              </div>
            </div>
          </div>

          <form className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#2D6A4F]"
                required
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#2D6A4F]"
                required
              />
            </div>

            <div>
              <label
                htmlFor="subject"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#2D6A4F]"
                required
              />
            </div>

            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className="w-full px-4 py-2 rounded-2xl border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#2D6A4F]"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full bg-[#2D6A4F] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-[#1A4031] transition-colors"
            >
              Send Message
            </button>
          </form>
        </div>{" "}
      </div>{" "}
    </div>
  );
}
