import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Hero from "../components/Hero";
import Blogs from "../components/Blogs";
import Faq from "../components/Faq";

const Home = () => {
  return (
    <>
      <Hero />
      <Blogs />
      <Faq />
    </>
  );
};

export default Home;
