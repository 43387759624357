import { useState, useEffect } from "react";
import { Search, Filter } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import { ArrowRight, ArrowUpRight } from "lucide-react";
const categories = [
  "Environment",
  "Conservation",
  "Sustainability",
  "Climate Change",
  "Wildlife",
];
const blogPosts = [
  {
    id: 1,
    title: "10 Ways to Reduce Your Carbon Footprint",
    excerpt:
      "Simple steps you can take to minimize your impact on the environment.",
    category: "Environment",
    img: "/placeholder.svg?height=200&width=300",
  },
  {
    id: 2,
    title: "The Importance of Biodiversity",
    excerpt:
      "Understanding why preserving biodiversity is crucial for our planet's health.",
    category: "Conservation",
    img: "/placeholder.svg?height=200&width=300",
  },
  {
    id: 3,
    title: "Sustainable Living in Urban Areas",
    excerpt: "Practical tips for adopting a sustainable lifestyle in cities.",
    category: "Sustainability",
    img: "/placeholder.svg?height=200&width=300",
  },
  // Add more blog posts as needed
];

function Blogs() {
  //fetch
  const [posts, setPosts] = useState([]);

  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://bezaapi.plutotechnologies.org/api/${cat}`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const filteredBlogs = blogPosts.filter(
    (blog) =>
      blog.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory === "" || blog.category === selectedCategory)
  );

  return (
    <div className="bg-white min-h-screen py-24">
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        <h1 className="text-5xl font-bold mb-8">Our Blog</h1>

        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-12 space-y-4 md:space-y-0">
          <div className="relative w-full md:w-96">
            <input
              type="text"
              placeholder="Search blogs..."
              className="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#2D6A4F]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute right-3 top-2.5 text-gray-400" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post) => (
            <Link className="group" key={post.id} to={`/post/${post.id}`}>
              <div className="bg-white rounded-3xl overflow-hidden shadow-lg transition-transform transform group-hover:scale-105">
                <img
                  src={`../upload/${post.img}`}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <span className="inline-block px-3 py-1 bg-[#E5EDDE] text-[#2D6A4F] rounded-full text-sm font-semibold mb-2">
                    {post.cat}
                  </span>
                  <h2 className="text-xl font-bold mb-2 group-hover:text-[#2D6A4F]">
                    {post.title}
                  </h2>
                  <button className="text-black font-semibold flex items-center">
                    Read More <ArrowRight className="ml-2 w-5 h-5" />
                  </button>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blogs;
