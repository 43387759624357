import React, { useEffect, useState } from "react";
import Edit from "../img/edit.png";
import Delete from "../img/delete.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import axios from "axios";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import DOMPurify from "dompurify";
// import { Link } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
const Single = () => {
  const [post, setPost] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  const postId = location.pathname.split("/")[2];

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://bezaapi.plutotechnologies.org/api/posts/${postId}`
        );
        setPost(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://bezaapi.plutotechnologies.org/api/posts/${postId}`
      );
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  return (
    <>
      <div className="bg-white min-h-screen py-10">
        <div className="max-w-4xl mx-auto px-4 md:px-6">
          <Link
            href="/blogs"
            className="inline-flex items-center text-[#2D6A4F] hover:underline mb-8"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Blogs
          </Link>

          <img
            src={`../upload/${post?.img}`}
            alt={post.title}
            width={800}
            height={400}
            className="w-full h-64 md:h-96 object-cover rounded-3xl mb-8"
          />

          <span className="inline-block px-3 py-1 bg-[#E5EDDE] text-[#2D6A4F] rounded-full text-sm font-semibold mb-4">
            {post.cat}
          </span>

          <h1 className="text-4xl md:text-5xl font-bold mb-4">{post.title}</h1>

          <div className="flex items-center text-gray-600 mb-8">
            <span>{post.username}</span>
            <span className="mx-2">•</span>
            <span>Posted {moment(post.date).fromNow()}</span>
            {currentUser && currentUser.username === post.username && (
              <div className="edit">
                <Link to={`/write?edit=2`} state={post}>
                  <img src={Edit} alt="" />
                </Link>
                <img onClick={handleDelete} src={Delete} alt="" />
              </div>
            )}
          </div>

          <div
            className="prose prose-lg max-w-none mb-16"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(post.description),
            }}
          />
          <div className="single">
            {/* <div className="content">
              <img src={`../upload/${post?.img}`} alt="" />
              <div className="user">
                {post.userImg && <img src={post.userImg} alt="" />}
                <div className="info">
                  <span>{post.username}</span>
                  <p>Posted {moment(post.date).fromNow()}</p>
                </div>
                {currentUser.username === post.username && (
                  <div className="edit">
                    <Link to={`/write?edit=2`} state={post}>
                      <img src={Edit} alt="" />
                    </Link>
                    <img onClick={handleDelete} src={Delete} alt="" />
                  </div>
                )}
              </div>
              <h1>{post.title}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.description),
                }}
              ></p>{" "}
            </div> */}
            <Menu cat={post.cat} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Single;
