import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ArrowRight, Upload } from "lucide-react";

const Write = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [cat, setCat] = useState("");
  const [file, setFile] = useState(null);
  //older versions
  const state = useLocation().state;
  const [value, setValue] = useState(state?.title || "");
  // const [file, setFile] = useState(null);
  // const [cat, setCat] = useState(state?.cat || "");

  const navigate = useNavigate();
  // const upload = async () => {
  //   if (!file) return "";

  //   try {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     const res = await axios.post("/upload", formData);
  //     return res.data; // Assuming the URL of the uploaded file is returned
  //   } catch (err) {
  //     console.error("File upload error:", err);
  //     throw err; // Re-throw to handle in the calling function
  //   }
  // };
  const upload = async () => {
    if (!file) return ""; // Check if file exists

    try {
      const formData = new FormData();
      formData.append("file", file); // Ensure this matches the key used in the backend

      console.log([...formData]); // Log FormData contents for debugging

      const res = await axios.post(
        "https://bezaapi.plutotechnologies.org/api/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data; // The server should respond with the file URL or name
    } catch (err) {
      console.error("File upload error:", err);
      throw err;
    }
  };

  // const upload = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     const res = await axios.post("/upload", formData);
  //     return res.data;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const handleClick = async (e) => {
  //   e.preventDefault();
  //   const imgUrl = await upload();

  //   try {
  //     state
  //       ? await axios.put(`/posts/${state.id}`, {
  //           title,
  //           desc: value,
  //           cat,
  //           img: file ? imgUrl : "",
  //         })
  //       : await axios.post(`/posts/`, {
  //           title,
  //           desc: value,
  //           cat,
  //           img: file ? imgUrl : "",
  //           date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
  //         });
  //         navigate("/")
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const handleClick = async (e) => {
    e.preventDefault();
    const imgUrl = await upload();

    try {
      if (state) {
        await axios.put(
          `https://bezaapi.plutotechnologies.org/api/posts/${state.id}`,
          {
            title,
            desc: value,
            cat,
            img: file ? imgUrl : "",
          }
        );
      } else {
        await axios.post(`https://bezaapi.plutotechnologies.org/api/posts/`, {
          title,
          desc: value,
          cat,
          img: file ? imgUrl : "",
          date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
        });
      }
      navigate("/");
    } catch (err) {
      console.error("Error creating/updating post:", err);
      alert("Error: " + (err.response?.data || "Something went wrong"));
    }
  };

  return (
    <>
      <div className="bg-white min-h-screen py-24">
        <div className="max-w-4xl mx-auto px-4 md:px-6">
          <h1 className="text-5xl font-bold mb-8">Post a New Blog</h1>

          <form className="space-y-6">
            <div>
              <label
                htmlFor="title"
                className="block text-lg font-medium text-gray-700 mb-2"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-4 py-2 rounded-2xl border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#2D6A4F]"
                required
              />
            </div>

            <div>
              <label
                htmlFor="cat"
                className="block text-lg font-medium text-gray-700 mb-2"
              >
                Category
              </label>
              <input
                type="text"
                id="category"
                value={cat}
                onChange={(e) => setCat(e.target.value)}
                className="w-full px-4 py-2 rounded-2xl border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#2D6A4F]"
                required
              />
            </div>

            <div>
              <label
                htmlFor="content"
                className="block text-lg font-medium text-gray-700 mb-2"
              >
                Content
              </label>
              <ReactQuill
                className="editor"
                theme="snow"
                value={value}
                onChange={setValue}
              />
            </div>

            <div>
              <label
                htmlFor="file"
                className="block text-lg font-medium text-gray-700 mb-2"
              >
                Featured file
              </label>
              <div className="flex items-center space-x-4">
                <label className="cursor-pointer bg-[#E5EDDE] text-[#2D6A4F] px-4 py-2 rounded-full hover:bg-[#2D6A4F] hover:text-white transition-colors">
                  <Upload className="inline-block w-5 h-5 mr-2" />
                  Choose File
                  <input
                    type="file"
                    id="file"
                    accept="file/*"
                    onChange={(e) => setFile(e.target.files?.[0] || null)}
                    className="hidden"
                  />
                </label>
                {file && <span className="text-gray-600">{file.name}</span>}
              </div>
            </div>

            <button
              onClick={handleClick}
              className="bg-[#2D6A4F] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-[#1A4031] transition-colors flex items-center"
            >
              Publish Blog
              <ArrowRight className="ml-2 w-5 h-5" />
            </button>
          </form>
        </div>
      </div>
      {/* old version */}
      {/* <div className="add">
        <div className="content">
          <input
            type="text"
            placeholder="Title"
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className="editorContainer">
            <ReactQuill
              className="editor"
              theme="snow"
              value={value}
              onChange={setValue}
            />
          </div>
        </div>
        <div className="menu">
          <div className="item">
            <h1>Publish</h1>
            <span>
              <b>Status: </b> Draft
            </span>
            <span>
              <b>Visibility: </b> Public
            </span>
            <input
              style={{ display: "none" }}
              type="file"
              id="file"
              name=""
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label className="file" htmlFor="file">
              Upload Image
            </label>
            <div className="buttons">
              <button>Save as a draft</button>
              <button onClick={handleClick}>Publish</button>
            </div>
          </div>
          <div className="item">
            <h1>Category</h1>
            <div className="cat">
              <input
                type="radio"
                checked={cat === "art"}
                name="cat"
                value="art"
                id="art"
                onChange={(e) => setCat(e.target.value)}
              />
              <label htmlFor="art">Art</label>
            </div>
            <div className="cat">
              <input
                type="radio"
                checked={cat === "science"}
                name="cat"
                value="science"
                id="science"
                onChange={(e) => setCat(e.target.value)}
              />
              <label htmlFor="science">Science</label>
            </div>
            <div className="cat">
              <input
                type="radio"
                checked={cat === "technology"}
                name="cat"
                value="technology"
                id="technology"
                onChange={(e) => setCat(e.target.value)}
              />
              <label htmlFor="technology">Technology</label>
            </div>
            <div className="cat">
              <input
                type="radio"
                checked={cat === "cinema"}
                name="cat"
                value="cinema"
                id="cinema"
                onChange={(e) => setCat(e.target.value)}
              />
              <label htmlFor="cinema">Cinema</label>
            </div>
            <div className="cat">
              <input
                type="radio"
                checked={cat === "design"}
                name="cat"
                value="design"
                id="design"
                onChange={(e) => setCat(e.target.value)}
              />
              <label htmlFor="design">Design</label>
            </div>
            <div className="cat">
              <input
                type="radio"
                checked={cat === "food"}
                name="cat"
                value="food"
                id="food"
                onChange={(e) => setCat(e.target.value)}
              />
              <label htmlFor="food">Food</label>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Write;
