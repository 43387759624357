import placeholderimage from "../img/image.png";
export default function About() {
  return (
    <div className="bg-white min-h-screen py-24">
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        <h1 className="text-5xl font-bold mb-8">About GreenWorld</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-16">
          <div>
            <p className="text-xl mb-6">
              GreenWorld is a non-profit organization dedicated to protecting
              and preserving our planet's natural resources. Founded in 2005, we
              have been at the forefront of environmental conservation,
              sustainable living, and climate change awareness.
            </p>
            <p className="text-xl mb-6">
              Our mission is to inspire and empower individuals and communities
              to take action towards a more sustainable future. Through
              education, advocacy, and hands-on projects, we strive to make a
              positive impact on the environment and create a world where humans
              live in harmony with nature.
            </p>
          </div>
          <div className="relative h-96 rounded-3xl overflow-hidden">
            <img
              src={placeholderimage}
              alt="GreenWorld team"
              layout="fill"
              objectFit="cover"
            />
          </div>
        </div>

        <h2 className="text-3xl font-bold mb-8">Our Core Values</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {[
            {
              title: "Sustainability",
              description:
                "We believe in promoting sustainable practices in all aspects of life to ensure a better future for generations to come.",
            },
            {
              title: "Education",
              description:
                "We are committed to educating and raising awareness about environmental issues and solutions.",
            },
            {
              title: "Action",
              description:
                "We encourage and facilitate direct action to address environmental challenges at local and global levels.",
            },
          ].map((value, index) => (
            <div key={index} className="bg-[#E5EDDE] rounded-3xl p-6">
              <h3 className="text-2xl font-bold mb-4 text-[#2D6A4F]">
                {value.title}
              </h3>
              <p className="text-gray-700">{value.description}</p>
            </div>
          ))}
        </div>

        <h2 className="text-3xl font-bold mb-8">Our Impact</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {[
            { number: "1M+", label: "Trees Planted" },
            { number: "500K", label: "Volunteers Engaged" },
            { number: "100+", label: "Conservation Projects" },
            { number: "50+", label: "Countries Impacted" },
          ].map((stat, index) => (
            <div key={index} className="text-center">
              <div className="text-4xl font-bold text-[#2D6A4F] mb-2">
                {stat.number}
              </div>
              <div className="text-xl text-gray-700">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
