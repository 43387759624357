import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Logo from "../img/logo.png";
import {
  Facebook,
  X,
  Twitter,
  Home,
  Instagram,
  Linkedin,
  ArrowRight,
  AudioLines,
} from "lucide-react";
const Footer = () => {
  return (
    <footer className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        <div className="relative">
          <div className="absolute inset-0 bg-[#E5EDDE] rounded-[100px] -rotate-3"></div>
          <div className="relative z-10 text-center py-16">
            <h2 className="text-4xl font-bold mb-4">
              Join our community
              <br />
              and make a difference
            </h2>
            <p className="text-xl mb-8">
              Subscribe to our newsletter for updates on our latest projects and
              ways to get involved.
            </p>
            <form className="max-w-md mx-auto mb-8">
              <div className="flex">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-grow px-4 py-2 rounded-l-full border-2 border-[#2D6A4F] focus:outline-none focus:ring-2 focus:ring-[#2D6A4F]"
                  required
                />
                <button
                  type="submit"
                  className="bg-[#2D6A4F] text-white px-6 py-2 rounded-r-full hover:bg-[#1A4031] transition-colors flex items-center"
                >
                  Subscribe
                  <ArrowRight className="ml-2 w-5 h-5" />
                </button>
              </div>
            </form>
            <div className="flex justify-center space-x-6">
              <Link href="#" className="text-[#2D6A4F] hover:text-[#1A4031]">
                <Facebook className="w-8 h-8" />
              </Link>
              <Link href="#" className="text-[#2D6A4F] hover:text-[#1A4031]">
                <Twitter className="w-8 h-8" />
              </Link>
              <Link href="#" className="text-[#2D6A4F] hover:text-[#1A4031]">
                <Instagram className="w-8 h-8" />
              </Link>
              <Link href="#" className="text-[#2D6A4F] hover:text-[#1A4031]">
                <Linkedin className="w-8 h-8" />
              </Link>
            </div>
          </div>
          <div className="absolute top-4 left-4 w-8 h-8 bg-white rounded-full"></div>
          <div className="absolute bottom-4 right-4 w-8 h-8 bg-white rounded-full"></div>
          <div className="absolute top-1/4 right-8 w-6 h-6 bg-white rounded-full"></div>
          <div className="absolute bottom-1/4 left-8 w-6 h-6 bg-white rounded-full"></div>
        </div>

        <div className="mt-16 grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">About Us</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  href="/about"
                  className="text-gray-600 hover:text-[#2D6A4F]"
                >
                  Our Mission
                </Link>
              </li>
              <li>
                <Link
                  href="/about"
                  className="text-gray-600 hover:text-[#2D6A4F]"
                >
                  Our Team
                </Link>
              </li>
              <li>
                <Link
                  href="/about"
                  className="text-gray-600 hover:text-[#2D6A4F]"
                >
                  Partners
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Get Involved</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  href="/volunteer"
                  className="text-gray-600 hover:text-[#2D6A4F]"
                >
                  Volunteer
                </Link>
              </li>
              <li>
                <Link
                  href="/donate"
                  className="text-gray-600 hover:text-[#2D6A4F]"
                >
                  Donate
                </Link>
              </li>
              <li>
                <Link
                  href="/campaigns"
                  className="text-gray-600 hover:text-[#2D6A4F]"
                >
                  Campaigns
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Resources</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  href="/blogs"
                  className="text-gray-600 hover:text-[#2D6A4F]"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  href="/podc asts"
                  className="text-gray-600 hover:text-[#2D6A4F]"
                >
                  Podcasts
                </Link>
              </li>
              <li>
                <Link
                  href="/faq"
                  className="text-gray-600 hover:text-[#2D6A4F]"
                >
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Contact</h3>
            <ul className="space-y-2">
              <li className="text-gray-600">
                123 Green Street, Eco City, 12345
              </li>
              <li className="text-gray-600">info@greenworld.org</li>
              <li className="text-gray-600">+1 (555) 123-4567</li>
            </ul>
          </div>
        </div>

        <div className="mt-16 pt-8 border-t border-gray-200 text-center text-gray-600">
          <p>
            &copy; {new Date().getFullYear()} EcoAware Youth . All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
