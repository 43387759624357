import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Write from "./pages/Write";
import Home from "./pages/Home";
import Single from "./pages/Single";
import Sing from "./pages/Sing";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./index.css";
import Blogs from "./pages/Blogs";
import Podcasts from "./pages/Podcasts";
import Singlepodcast from "./pages/Singlepodcast";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/post/:id",
        element: <Single />,
      },
      {
        path: "/podcast/:id",
        element: <Singlepodcast />,
      },
      {
        path: "/write",
        element: <Write />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/sing",
        element: <Sing />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/podcasts",
        element: <Podcasts />,
      },
    ],
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {
  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
